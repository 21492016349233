import Axios from "axios";

const domainName = 'api.sabq.win'
const api = Axios.create({
    // baseURL: 'http://localhost:3000/api',
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
        'Content-Type': ' application/json',
        'Accept': 'application/json',
        'accept-language': 'ar'
    }
});

export default api; 