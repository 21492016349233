import React, { Component } from 'react';
import { setStorage, getStorage, checkStorage, deleteStorage } from '../helperComponents/localStorage'
import { encrypt } from '../helperComponents/encrypt'
import axios from '../helperComponents/axios'
export const AppContext = React.createContext();
export class AppContextProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_logged: checkStorage('is_logged') ? getStorage('is_logged') : encrypt('false'),
            login_token: getStorage('login_token'),

            userInfo: { //logged in user info
                id: getStorage('user_id'),
                name: getStorage('name'),
                profile_picture: getStorage('profile_picture'),
            },

        }
        this.login = this.login.bind(this)
        this.logout = this.logout.bind(this)
        this.setContext = this.setContext.bind(this)
        this.updateUserInfo = this.updateUserInfo.bind(this)
        this.updateLoginStorage = this.updateLoginStorage.bind(this)
        this.deleteUserStorage = this.deleteUserStorage.bind(this)
    }

    static getDerivedStateFromProps(props, state) {
        // alert('d')
        var hours = 1.5; // Reset when last action is more than hour and half an hour
        var now = new Date().getTime();
        var setupTime = parseInt(localStorage.getItem('setupTime'));
        const deleteUserStorage = () => {
            deleteStorage('is_logged')
            deleteStorage('login_token')
        }
        if (setupTime == null) {
            deleteUserStorage()
            localStorage.setItem('setupTime', now)
            return { is_logged: encrypt('false'), login_token: encrypt('') }


        } else {
            if (now - setupTime > hours * 60 * 60 * 1000) {
                deleteUserStorage()
                deleteStorage('setupTime')
                return { is_logged: encrypt('false'), login_token: encrypt('') }

            } else {
                localStorage.setItem('setupTime', now)
            }

        }
        return {}
    }
    setContext(key, value) {
        if (key in this.state)
            this.setState({ [key]: value })
    }
    updateUserInfo(id, name, profile_picture) {
        this.setState({
            userInfo: {
                id: id,
                name: name,
                profile_picture: profile_picture,
            }
        }, () => {
            setStorage('user_id', id)
            setStorage('name', name)
            setStorage('profile_picture', profile_picture)
        })

    }
    updateLoginStorage() {
        setStorage('is_logged', this.state.is_logged)
        setStorage('login_token', this.state.login_token)

    }
    login(token) {
        this.setState({ is_logged: encrypt('true'), login_token: encrypt(token) }, () => {
            this.updateLoginStorage()
        })
    }
    logout() {
        axios({
            method: 'post',
            url: `/admin/logout`,
        }
        ).then((res) => {
            this.deleteUserStorage()

            console.log("RESPONSE RECEIVED: ", res);

        }).catch((err) => {

            console.log("AXIOS ERROR: ", err);
        })


    }
    deleteUserStorage() {
        this.setState({ is_logged: encrypt('false'), login_token: encrypt('') }, () => {
            deleteStorage('is_logged')
            deleteStorage('login_token')
        })
    }
    render() {

        return (
            <AppContext.Provider value={{
                state: this.state,
                setContext: this.setContext,
                login: this.login,
                logout: this.logout,
                updateUserInfo: this.updateUserInfo,
                deleteUserStorage: this.deleteUserStorage
            }}>
                {this.props.children}
            </AppContext.Provider>);
    }
}


